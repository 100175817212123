import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { openLoginDialog } from '../components/login-dialog/login-dialog.component';
import { singnUpDialog } from '../components/sign-up-dialog/sign-up-dialog.component';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  activeLang = this.translateService.currentLang;
  dashboardLoginUrl = environment.APP_DASHBOARD_LOGIN;
  dashboardRegisterUrl = environment.APP_DASHBOARD_REGISTER;
  @HostBinding('class') classes = 'sticky top-0 bg-white z-50';

  isMenuShown = '-translate-x-full';
  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.activeLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe({
      next: () => {
        this.activeLang = this.translateService.currentLang;
      },
    });
  }

  onWindowScroll() {
    if (window.pageYOffset > 0) {
      // this.classes = 'sticky top-0 bg-white z-50 cloud-shadow';
      this.classes = 'sticky top-0 bg-white z-50 header-shadow';
    } else {
      this.classes = 'sticky top-0 bg-white z-50';
    }
  }

  onMenuClicked() {
    if (this.isMenuShown === '-translate-x-full') {
      this.isMenuShown = '';
    } else {
      this.isMenuShown = '-translate-x-full';
    }
  }

  // onLogin() {
  //   this.closeMenu();
  //   openLoginDialog(this.dialog);
  // }

  // onSignup() {
  //   this.closeMenu();
  //   singnUpDialog(this.dialog);
  // }
  closeMenu() {
    this.isMenuShown = '-translate-x-full';
  }

  ngOnDestroy(): void {}
}
