import { CookieConfirmService } from './cookie-confirm.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Lang } from './../interfaces/lang.model';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subscription, map, retry, finalize } from 'rxjs';
import { DropdownDataService } from './dropdown-data.service';
import { DateAdapter } from '@angular/material/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserLanguage } from '../interfaces/user-language.model';
import { CandidateNotificationsService } from './candidate-notifications.service';
import { CompanyNotificationsService } from './company-notifications.service';
import { CandidateInfoService } from './candidate-info.service';
import { CompanyInfoService } from './company-info.service';
import { isPlatformBrowser } from '@angular/common';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

const LANG_COOKIE = '_joobzz_lang';
const DEFAULT_LANG = 'en';

@Injectable({
  providedIn: 'root',
})
export class LangSelectService implements OnDestroy {
  settingLang = false;
  activeLangSubscription = new Subscription();
  private browserLangsGerman = [
    'de',
    'de-at',
    'de-de',
    'de-li',
    'de-lu',
    'de-ch',
  ];
  private browserLangsFrench = [
    'fr',
    'fr-be',
    'fr-ca',
    'fr-fr',
    'fr-lu',
    'fr-mc',
    'fr-ch',
  ];
  private languages: Lang[] = [
    {
      value: 'en',
      display_value: 'ENG',
      img_url: './assets/img/eng-flag.svg',
    },
    {
      value: 'de',
      display_value: 'GER',
      img_url: './assets/img/ger-flag.svg',
    },
    {
      value: 'fr',
      display_value: 'FRA',
      img_url: './assets/img/fra-flag.svg',
    },
  ];
  constructor(
    private translate: TranslateService,
    private cookieService: SsrCookieService,
    private cookieConfirmService: CookieConfirmService,
    private dateAdapter: DateAdapter<Date>,
    private dropDownDataService: DropdownDataService,
    private http: HttpClient,
    private candidateNotificationService: CandidateNotificationsService,
    private companyNotificationsService: CompanyNotificationsService,
    private companyInfoService: CompanyInfoService,
    private candidateInfoService: CandidateInfoService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.getActiveLang();
    }
  }

  getActiveLang() {
    this.activeLangSubscription = this.translate.onLangChange
      .pipe(
        map((e) => {
          if (e.lang === 'en') {
            this.dateAdapter.setLocale('en-GB');
            return e.lang;
          } else if (e.lang === 'fr') {
            this.dateAdapter.setLocale('fr_CH');
            return e.lang;
          } else {
            this.dateAdapter.setLocale('de_CH');
            return e.lang;
          }
        })
      )
      .subscribe((res) => {
        // localStorage.setItem('appLang', res);
        console.log(res);
        this.cookieService.set(LANG_COOKIE, res);
      });
  }

  getLanguages() {
    return this.languages;
  }

  getInitialLanguage() {
    if (this.cookieService.check(LANG_COOKIE)) {
      return this.cookieService.get(LANG_COOKIE);
    }
    const browserLang = this.translate.getBrowserLang();
    if (this.browserLangsFrench.includes(browserLang!)) {
      return 'fr';
    } else if (this.browserLangsGerman.includes(browserLang!)) {
      return 'de';
    } else {
      return DEFAULT_LANG;
    }
  }

  setInitalLanguage() {
    if (this.cookieConfirmService.cookiesAccepted()) {
      if (this.cookieService.check(LANG_COOKIE)) {
        this.translate.use(this.cookieService.get(LANG_COOKIE));
      } else {
        this.cookieService.set(LANG_COOKIE, DEFAULT_LANG, 400);
        this.translate.use(DEFAULT_LANG);
      }
    } else {
      this.translate.use(DEFAULT_LANG);
    }
  }

  private langKeyModify(lng: 'en' | 'de' | 'fr') {
    if (lng === 'de') {
      return 'de-DE';
    } else if (lng === 'fr') {
      return 'fr-FR';
    } else {
      return 'en-US';
    }
  }

  setActiveLang(ln: string) {
    this.translate.use(ln);
    if (ln !== this.cookieService.get(LANG_COOKIE)) {
      // this.setLanguageRemote(this.setLangId(ln));
    }

    if (this.cookieConfirmService.cookiesAccepted()) {
      this.cookieService.set(LANG_COOKIE, ln, 400, '/');
    }
  }

  private setLangId(ln: string) {
    if (ln === 'en') {
      return 1;
    }
    if (ln === 'de') {
      return 2;
    }
    if (ln === 'fr') {
      return 3;
    }
    return 0;
  }

  getLanguageRemote(userId: string) {
    const params = new HttpParams().set('id', userId);

    return this.http
      .get<number>(
        `${environment.API_JOOBZZ}${environment.API_GET_USER_LANGUAGE}`,
        { params }
      )
      .pipe(retry({ count: 2, delay: 700 }));
  }

  // setLanguageRemote(languageId: number, userId?: string) {
  //   if (isPlatformBrowser(this.platformId)) {
  //     if (typeof userId === 'undefined') {
  //       userId = localStorage.getItem('userId') || '';
  //     }
  //     this.settingLang = true;
  //     if (userId !== '') {
  //       this.http
  //         .post<UserLanguage>(
  //           `${environment.API_JOOBZZ}${environment.API_POST_USER_LANGUAGE}`,
  //           {
  //             userId: userId,
  //             languageId: languageId,
  //           }
  //         )
  //         .pipe(retry({ count: 2, delay: 700 }))
  //         .subscribe({
  //           next: (res) => {
  //             console.log(res);
  //           },
  //           error: (err) => {
  //             console.log(err);
  //           },
  //         });
  //     }
  //   }
  // }

  ngOnDestroy(): void {
    this.activeLangSubscription.unsubscribe();
  }
}
