import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter, map } from 'rxjs';
import { singnUpDialog } from '../components/sign-up-dialog/sign-up-dialog.component';
import { openLoginDialog } from '../components/login-dialog/login-dialog.component';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  dashboardLoginUrl = environment.APP_DASHBOARD_LOGIN;
  dashboardRegisterUrl = environment.APP_DASHBOARD_REGISTER;
  activeLang = this.translateService.currentLang;
  userLogedIn = false;
  url = '';
  urlsToHideBtn = ['/poslodavci', '/companies'];
  subscriptionRouter: Subscription = new Subscription();
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private translateService: TranslateService
  ) {}
  ngOnInit(): void {
    this.subscriptionRouter = this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        map((e) => {
          // e is now NavigationEnd
          return e;
        })
      )
      .subscribe((res) => {
        this.url = this.router.url;
      });

    this.activeLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe({
      next: () => {
        this.activeLang = this.translateService.currentLang;
      },
    });
  }

  // onSignUp() {
  //   singnUpDialog(this.dialog);
  // }

  // onLogin() {
  //   openLoginDialog(this.dialog);
  // }

  ngOnDestroy(): void {
    this.subscriptionRouter.unsubscribe();
  }
}
