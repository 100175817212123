<div class="py-6 px-14 dialog-shadow">
  <p class="text-center text-lg text-gray font-semibold mb-4">
    {{ 'home.modalCookieAccept.description' | translate }}
  </p>
  <mat-dialog-actions align="center" class="flex gap-x-16 gap-y-4">
    <button
      (click)="acceptCookies()"
      class="py-2.5 px-6 font-bold text-sm text-white rounded-md btn-shadow btn-blue transition-colors"
    >
      {{ 'home.modalCookieAccept.yes' | translate }}
    </button>
    <button
      mat-dialog-close
      class="py-2.5 px-6 font-bold text-sm text-gray rounded-md btn-shadow btn-gray transition-colors"
    >
      {{ 'home.modalCookieAccept.no' | translate }}
    </button>
  </mat-dialog-actions>
</div>
