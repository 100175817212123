import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';

const COOKIES_ACCEPTED_KEY = '_joobzz_cookies';
const COOKIE_FIRST_TIME_KEY = '_jobbzz_visited';

@Injectable({
  providedIn: 'root',
})
export class CookieConfirmService {
  private cookiesShown = new BehaviorSubject<boolean>(false);

  cookiesShown$ = this.cookiesShown.asObservable();

  constructor(private cookieService: CookieService) {}

  cookiesAccepted() {
    if (this.cookieService.check(COOKIES_ACCEPTED_KEY)) {
      return true;
    }
    return false;
  }

  cookiesFinished() {
    if (this.cookieIsFirstVisit()) {
      this.cookieFirstVisit();
      this.cookiesShown.next(true);
    }
  }

  cookiesRestartObs() {
    this.cookiesShown.next(false);
  }

  acceptCookies() {
    this.cookieService.set(COOKIES_ACCEPTED_KEY, 'true', 400);
  }

  cookieFirstVisit() {
    if (this.cookieService.check(COOKIES_ACCEPTED_KEY)) {
      this.cookieService.set(COOKIE_FIRST_TIME_KEY, 'true', 400);
    }
  }

  cookieIsFirstVisit() {
    if (this.cookieService.check(COOKIE_FIRST_TIME_KEY)) {
      return false;
    }
    return true;
  }
}
