import { isPlatformBrowser } from '@angular/common';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Observable } from 'rxjs';

@Injectable()
export class LangInterceptor implements HttpInterceptor {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const cookieService = inject(SsrCookieService);

    const lang =
      cookieService.get('_joobzz_lang') === 'de'
        ? 'de-DE'
        : cookieService.get('_joobzz_lang') === 'fr'
        ? 'fr-FR'
        : 'en-US';

    if (lang) {
      if (request.url.includes('AdminDashboard/approve')) {
        return next.handle(request.clone());
      } else {
        const cloned = request.clone({
          headers: request.headers.set('Accept-Language', `${lang}`),
        });
        return next.handle(cloned);
      }
    } else {
      const cloned2 = request.clone({
        headers: request.headers.set('Accept-Language', `en-US`),
      });
      return next.handle(cloned2);
    }
  }
}
