import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationStatus } from '../interfaces/notification-status.model';
import { SingleNotification } from '../interfaces/single-notification.model';
import { NotificationCreate } from '../interfaces/notification-create.model';
import { RecruiterActivities } from '../interfaces/recruiter-activities.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyNotificationsService {
  private companyNotificationStatus = new BehaviorSubject<NotificationStatus>(
    {} as NotificationStatus
  );
  private companyNotificatinsAll = new BehaviorSubject<SingleNotification[]>(
    [] as SingleNotification[]
  );
  private recruiterActivities = new BehaviorSubject<RecruiterActivities[]>(
    [] as RecruiterActivities[]
  );

  companyNotificationStatus$ = this.companyNotificationStatus.asObservable();
  companyNotificatinsAll$ = this.companyNotificatinsAll.asObservable();
  recruiterActivities$ = this.recruiterActivities.asObservable();

  constructor(private http: HttpClient) {}

  getCompanyNotificationStatus() {
    const userId = localStorage.getItem('userId');
    return this.http
      .get<NotificationStatus>(
        `${environment.API_JOOBZZ}${environment.API_GET_COMPANY_NOTIFICATIONS}/${userId}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.companyNotificationStatus.next(res);
        },
      });
  }

  setCompanyNotificationStatus(status: NotificationStatus) {
    return this.http
      .post(
        `${environment.API_JOOBZZ}${environment.API_POST_COMPANY_NOTIFICATIONS_CHANGE}`,
        status
      )
      .pipe(retry({ count: 2, delay: 700 }));
  }

  getAllCompanyNotifications() {
    const userId = localStorage.getItem('userId');
    return this.http
      .get<SingleNotification[]>(
        `${environment.API_JOOBZZ}${environment.API_GET_COMPANY_ALL_NOTIFICATIONS}/${userId}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.companyNotificatinsAll.next(res);
        },
      });
  }

  setCandidateNotificationRead(notificationId: number) {
    return this.http
      .get(
        `${environment.API_JOOBZZ}${environment.API_GET_SET_NOTIFICATION_READ}/${notificationId}`
      )
      .pipe(retry({ count: 2, delay: 700 }));
  }

  createViewNotification(candidateId: number) {
    const obj = {} as NotificationCreate;
    obj.candidateId = candidateId;
    obj.recruiterId = Number(localStorage.getItem('recruiterId'));
    obj.typeOfNotificationId = 3;
    return this.http
      .post(
        `${environment.API_JOOBZZ}${environment.API_POST_CREATE_NOTIFICATION}`,
        obj,
        { responseType: 'text' }
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          console.log(res);
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  getRecruiterActivities() {
    const userId = localStorage.getItem('userId');
    return this.http
      .get<RecruiterActivities[]>(
        `${environment.API_JOOBZZ}${environment.API_GET_RECRUITER_ACTIVITIES}/${userId}`
      )
      .pipe(retry({ count: 2, delay: 700 }))
      .subscribe({
        next: (res) => {
          this.recruiterActivities.next(res);
        },
      });
  }
}
