import { LangSelectService } from './../../services/lang-select.service';
import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Lang } from 'src/app/interfaces/lang.model';
import { FormControl } from '@angular/forms';
import { DropdownDataService } from 'src/app/services/dropdown-data.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-lang-select',
  templateUrl: './lang-select.component.html',
  styleUrls: ['./lang-select.component.scss'],
})
export class LangSelectComponent implements OnInit {
  languages: Lang[] = [];
  selectedLang = '';
  langSelectForm = new FormControl('en');
  langImgUrl = '';
  activeFlag = {} as Lang[];
  activeSelectListFlags = {} as Lang[];

  constructor(
    private langSelectService: LangSelectService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.langSelectForm.valueChanges.subscribe((res) => {
      if (res === null) {
        res = 'en';
      } else {
        this.langSelectService.setActiveLang(res);
        this.setActiveFlag(res);
        this.filterFlags(res);
      }
    });
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    if (isPlatformBrowser(this.platformId)) {
      this.setActiveFlag(this.langSelectService.getInitialLanguage());
      this.filterFlags(this.langSelectService.getInitialLanguage());
      this.langSelectForm.setValue(this.langSelectService.getInitialLanguage());
      this.langSelectService.setActiveLang(
        this.langSelectService.getInitialLanguage()
      );
    }
  }

  ngOnInit(): void {
    this.languages = this.langSelectService.getLanguages();
    this.langSelectForm.setValue(this.langSelectService.getInitialLanguage());
    this.langImgUrl = this.languages[0].img_url;
    this.setActiveFlag(this.langSelectService.getInitialLanguage());
    this.filterFlags(this.langSelectService.getInitialLanguage());
  }

  setActiveFlag(lang: string) {
    this.activeFlag = this.languages.filter((e) => {
      return e.value === lang;
    });
    this.langImgUrl = this.activeFlag[0].img_url;
  }

  filterFlags(lang: string) {
    this.activeSelectListFlags = this.languages.filter((e) => {
      return e.value !== lang;
    });
  }
}
